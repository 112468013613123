import React, { useContext } from "react";

import "./Portfolio.css";
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css";
import Sidebar from "../../img/sidebar.png";
import Ecommerce from "../../img/ecommerce.png";
import HOC from "../../img/hoc.png";
import MusicApp from "../../img/musicapp.png";
import Car from "../../img/car.png";
import Hn from "../../img/hn.png";
import Real from "../../img/real.png";
import { themeContext } from "../../Context";
const Portfolio = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  return (
    <div className="portfolio" id="portfolio">
      {/* heading */}
      <span style={{color: darkMode?'white': ''}}>Recent Projects</span>
      <span>Portfolio</span>

      {/* slider */}
      <Swiper
      
        spaceBetween={30}
        slidesPerView={2}
        grabCursor={true}
        className="portfolio-slider"

        breakpoints={{
          // when window width is >= 640px
          300: {
            width: 340,
            slidesPerView: 1,
            spaceBetween:10,
          },
          // when window width is >= 768px
          768: {
            width: 1100,
            slidesPerView: 2,
          },
        }}
      >
        <SwiperSlide>
          <img src={Sidebar} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Real} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Ecommerce} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={MusicApp} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={HOC} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Car} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Hn} alt="" />
        </SwiperSlide>
      </Swiper>
    </div>



  );
};



export default Portfolio;
